<template>
  <div style="background: rgba(247, 247, 247, 1);padding-right: 40rem">
    <div class="title" style="position: relative">
      <img alt="" class="bg" src="../../../../assets/img/fresh-registration-details-bg.png"/>
      <div class="title-title">
        <div style="margin-top: 19rem;margin-left: 24rem"></div>
      </div>
      <div class="title-bottom">
        <div style="display: flex;min-height: 50rem;align-items: end">
          <Item :value="form.approve_status_name" label="审批状态"></Item>
          <Item :value="form.student_name" label="学生姓名"></Item>
          <Item :value="form.name" label="家长姓名"></Item>
        </div>
        <div style="display: flex;min-height: 50rem;align-items: end">
          <Item :value="form.school_name" label="报名学校"></Item>
          <Item :value="form.grade_name" label="入学年级"></Item>
          <Item :value="form.grade_year" label="入学年份"></Item>
        </div>
        <div style="display: flex;min-height: 50rem;align-items: end">
          <Item :value="form.student_type_name" label="学生类型"></Item>
          <Item :value="form.prepare_study_type" label="学习方向"></Item>
          <Item v-if="form.student_type_name === '复读生'" :value="form.prepare_major" label="专业方向"></Item>
          <Item v-else label="" value=""></Item>
        </div>
        <div v-if="form.student_type_name === '应届生'" style="display: flex;min-height: 50rem;align-items: end">
          <Item :value="form.is_pre == 0 ? '否' : '是'" label="是否参加预报名"></Item>
          <Item :value="form.association_history" label="是否享受双胞胎优惠" labelWidth="200rem"></Item>
          <Item v-if="form.association_history !== '否'" :value="form.association_name" label="关联学生"></Item>
          <Item v-else value="" label=""></Item>
        </div>
      </div>
    </div>

    <div class="body">
      <div class="body-title">考试成绩</div>
      <div class="body-bottom" style="padding-top: 28rem">
        <div class="mark-box flex">
          <div class="mark-cell">
            <div>总分</div>
            <div>{{ form.total_score }}</div>
          </div>
          <div class="mark-cell" v-for="item in form.subject_score">
            <div>{{ item.subject_name }}</div>
            <div>{{ item.score }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="body-title">收费情况</div>
      <div class="body-bottom" style="padding-top: 28rem;display: flex">
        <el-form ref="ruleForm" :model="form" class="demo-form-inline" label-width="100rem">
          <el-form-item v-for="(item) in amount.goods" :label="item.goods_name" label-width="200rem">
            {{ item.goods_price }}
          </el-form-item>
        </el-form>
        <div style="margin-left: 68rem">
          <el-form v-if="amount.goods.length" :model="form" class="demo-form-inline" label-width="150rem">
            <el-form-item v-if="form.student_type_name === '应届生'" label="系统计算总金额">
              {{ amount.compute_money }}
            </el-form-item>
            <el-form-item label="手动录入总金额">
              {{ amount.payment_amount }}
            </el-form-item>
            <el-form-item v-if="form.student_type_name === '应届生'" label="系统与线下差额">
              {{ Number(amount.compute_money - amount.payment_amount).toFixed(2) }}
            </el-form-item>
            <el-form-item label="合同签约金额">
              {{ amount.sign_money }}
            </el-form-item>
            <el-form-item v-if="form.student_type_name === '应届生'" label="系统与合同差额">
<!--              {{ Number(amount.compute_money - amount.sign_money).toFixed(2) }}-->
              {{ Number(amount.diff).toFixed(2) }}
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <div class="button" style="margin-top: 76rem">
      <el-button @click="$router.back()">返回</el-button>
      <el-button v-show="isRefund" v-if="amount.is_refund_btn" @click="$router.push('../../client-server/off-line-student-supplement/refund-details?id='+$route.query.id)">查看退费详情</el-button>
    </div>
  </div>
</template>
<script>

import Item from "@/views/finance/enrollment/payment-progress/components/Item.vue";
import ListTemplate from "@/components/pages/List.vue"

export default {
	_config:{"route":{"path":"look","meta":{"title":"查看"}}},
  components: {ListTemplate, Item},
  data() {
    return {
      OSS_URL: process.env["VUE_APP_URL_OSS"],
      form: {
        approve_status_name: '',
        student_name: '',
        name: '',
        school_name: '',
        student_type_name: '',
        prepare_study_type: '',
        association_history: '',
        association_name: '',
        subject_score: [],
        student_total_grade: '',
        student_total_score: '',
        grade_name: '',
        grade_year: '',
        prepare_major: '',
        is_pre: '',
      },
      remarkVisible: false,
      modifyStatus: 0,
      amount: {
        goods: []
      },
      order: '',
      isRefund:true
    }
  },
  methods: {},

  created() {
    // 获取详情
    this.$_register.get('api/recruit-v2/approve/detail?id=' + this.$route.query.id).then(res => {
      this.form = res.data.data;
    })
    // 生成订单
    // this.$_register.post('api/recruit-v2/approve/post-order', {id: this.$route.query.id}).then(res => {
    //   this.order = res.data.data.order
      // 订单生成成功-----获取金额
      this.$_register.get('api/recruit-v2/approve/money-detail?id=' + this.$route.query.id).then(res_ => {
        this.amount = res_.data.data
      })
    // })
  }
}
</script>
<style lang="scss" scoped>
.title-title {
  background: #FFF;
  border-bottom: 1rem solid #F7F7F7;
  border-radius: 10rem 10rem 0 0;
  box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  font-size: 18rem;
  font-weight: bold;
  height: 60rem;
  justify-content: space-between;
}

.title-bottom {
  background: #FFF;
  border-radius: 0 0 10rem 10rem;
  box-sizing: border-box;
  margin-bottom: 24rem;
  padding-bottom: 24rem;
  padding-left: 32rem;
}

.body {
  margin: 24rem 0;

  & .body-title {
    background: #FFFFFF;
    border-bottom: 1rem solid #F7F7F7;
    border-radius: 10rem 10rem 0 0;
    box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.8);
    font-size: 18rem;
    font-weight: bold;
    height: 78rem;
    padding-left: 24rem;
    padding-top: 19rem;
  }

  & .body-bottom {
    background: #FFFFFF;
    border-radius: 0 0 10rem 10rem;
    box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);
    box-sizing: border-box;
    min-height: 148rem;
    padding: 0 24rem 24rem;

    & .body-bottom-label {
      color: rgba(0, 0, 0, 0.8);
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-size: 14rem;
      font-weight: 400;
      width: 96rem;
    }
  }
}

.el-table__body .cell {
  white-space: normal;
  word-wrap: break-word;
}

.bg {
  height: 60rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 220rem;
}

.mark-box{
  text-align: center;
  font-size: 14rem;
  padding-right: 40rem;

  .mark-cell{
    flex: 1;
    min-width: 60rem;
    max-width: 150rem;
    margin-left: -1rem;

    &>div{
      width: 100%;
      height: 36rem;
      line-height: 36rem;
      border: 1rem solid #ccc;
      margin-top: -1rem;
    }
  }
}

</style>
